<template>
  <div class="course_card">
    <div class="row" style="width: 100%">
      <!-- 基本信息 -->
      <div style="width: 250px; margin-right: 20px">
        <p class="title">{{ info.groupName }}-{{ info.teacherName }}</p>
        <p class="time">
          {{ info.timeRegion }}
        </p>
        <div class="progress">
          {{
            info.planRecordStatus == 3 || info.planRecordStatus == 4
              ? "原"
              : "当前"
          }}第{{ info.num }}节 | 共{{ info.total }}节
        </div>
      </div>

      <!-- 状态 -->
      <div>
        <!-- <Button class="confirm_btn" type="theme" v-if="info.planRecordStatus === 1"
          @click="toggleModalVisible">
          确认课时
        </Button> -->
        <div class="status" v-if="info.planRecordStatus !== 1">
          <div class="text" :style="`color: ${statusTxt[1]}`">
            {{ statusTxt[0] }}
          </div>
        </div>
      </div>
    </div>
    <ConfirmBottom
      :visible="confirmModal.visible"
      confirm1Btn="未上课"
      confirm2Btn="确认上课"
      @cancel="toggleModalVisible"
      @confirm1="handleConfirm(3)"
      @confirm2="handleConfirm(2)"
    >
      <p>
        <!-- Hi~ {{}} <br/> -->
        Hi~ {{ $store.state.userinfo.name }}同学 <br />
        上完{{ info.groupName }}-{{ info.teacherName }}的课了吗？
      </p>
      <p class="tips">
        {{ info.timeRegion }}
      </p>
      <p class="tips">
        {{
          info.planRecordStatus == 3 || info.planRecordStatus == 4
            ? "原"
            : "当前"
        }}第{{ info.num }}节 | 共{{ info.total }}节
      </p>
    </ConfirmBottom>
  </div>
</template>
<script>
import ConfirmBottom from "../../components/ConfirmBottom.vue";
import { RecordStatusEnumForLearner } from "../../../api/constant";
import { confirmClass } from "../../../api/learner";

export default {
  name: "learner-history-card",
  props: {
    info: {
      type: Object,
      require: true,
      default: () => {
        return {
          groupName: "",
        };
      },
    },
  },
  data() {
    return {
      confirmModal: {
        visible: false,
      },
    };
  },
  computed: {
    statusTxt() {
      const { planRecordStatus } = this.info;
      /*       const color = {
        2: '#BFBFBF', // 已上课
        3: '#BFBFBF', // 未上课
        7: '#BFBFBF', // 待确认
      }[planRecordStatus] */
      return [RecordStatusEnumForLearner[planRecordStatus], "#BFBFBF"];
    },
  },
  methods: {
    toggleModalVisible() {
      this.confirmModal.visible = !this.confirmModal.visible;
    },
    async handleConfirm(state) {
      try {
        //  2: '已上课',3: '未上课',
        const data = await confirmClass({
          recordStatus: state,
          planId: this.info.planId,
        });
        if (!data) throw "操作失败了";
        if (state === 2) {
          this.$toast("是不是感觉比昨天又进步了一点，继续加油~");
        } else {
          this.$toast("感谢反馈，若老师未上课则班主任将会跟进处理~");
        }
        this.info.planRecordStatus = state;
        this.toggleModalVisible();
      } catch (e) {
        // this.$toast(e)
      }
    },
  },
  components: { ConfirmBottom },
};
</script>
<style lang="stylus" scoped>
@import '../../../common.styl';
@import '../../../theme.styl';

.course_card {
  width: 100%;
  min-height: 86px;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 10px 12px;
  padding-left: 23px;
  font-size: fz14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 4px #6666660f;

  &+.course_card {
    margin-top: 10px;
  }

  .title {
    width: 250px;
    font-size: fz14px;
    color: #444F6A;
    line-height: fz18px;
    vendor(1);
  }

  .learner {
    font-size: fz14px;
    color: #10213F;
    line-height: fz14px;
    margin-top: 6px;
    vendor(1);
  }

  .progress, .time {
    font-size: fz12px;
    color: #C6C6C6;
    margin-top: 6px;
  }

  .status {
    width: 40px;
    word-break: break-all;
    margin-right: 12px;

    .text {
      font-size: fz12px;
      line-height: fz16px;
    }
  }

  .confirm_btn {
    width: 60px;
    font-size: fz12px;
    border-radius: 4px !important;
  }
}
</style>