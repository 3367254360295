let forbidScroll = (e) => {
  e.preventDefault()
}
export function isPassive() {
  let supportsPassiveOption = false
  try {
    addEventListener('test', null, Object.defineProperty({}, 'passive', {
      get: function () {
        supportsPassiveOption = true
        return ''
      }
    }))
  } catch (e) {
    console.error(e)
  }
  return supportsPassiveOption
}

export function disableScroll(boolean, node) {
  const ele = node || document
  if (boolean) {
    ele.addEventListener('touchmove', forbidScroll, isPassive() ? {
      capture: false,
      passive: false
    } : false)
    ele.addEventListener('mousewheel', forbidScroll, {
      passive: false
    })
    ele.addEventListener(
      'DOMMouseScroll',
      forbidScroll,
      false
    )
  } else {
    ele.removeEventListener('touchmove', forbidScroll, isPassive() ? {
      capture: false,
      passive: false
    } : false)
    ele.removeEventListener('mousewheel', forbidScroll)
    ele.removeEventListener('DOMMouseScroll', forbidScroll)
  }
}
