<template>
  <div class="confirm_bottom" v-if="visible">
    <div class="confirm_modal" @click="$emit('cancel')"></div>
    <div class="content">
      <div class="close" @click="$emit('cancel')"></div>
      <div class="wrap">
        <slot />
      </div>

      <div class="aline"></div>
      <div class="buttons">
        <div class="btn"
          @click="$emit('confirm1')">
          {{confirm1Btn}}
        </div>
        <div class="btn" @click="$emit('confirm2')">
          {{confirm2Btn}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {disableScroll} from '../../utils/disableScroll'

export default {
  props: ['visible', 'confirm1Btn', 'confirm2Btn'],
  watch: {
    visible(newVal) {
      disableScroll(newVal)
    }
  }
}
</script>
<style lang="stylus" scoped>
@import "../../theme.styl"
@import "../../common.styl"

.confirm_modal
  position fixed
  top 0
  left 50%
  transform translateX(-50%)
  z-index 1000
  width 100vw
  height 100vh
  background: rgba(4, 4, 15, 0.4);
.content
  width: 375px;
  background: #FEFFFE;
  border-radius: 8px 8px 0px 0px;
  position fixed
  bottom 0
  left 50%
  transform translateX(-50%)
  display flex
  flex-direction column
  justify-content space-between
  overflow hidden
  z-index 1001
  padding-bottom 12px
  .close
    width: 15px;
    height: 15px;
    position absolute
    right 19px
    top 18px
    bgFill()
    background-image url('./images/close.png')
  .wrap
    font-size fz16px
    color #444F6A
    line-height 24px
    padding 30px 17px 18px
    .tips
      font-size fz14px
      color #C6C6C6
      line-height 1
      margin-top 6px
  .aline
    width 100%
    height 1px
    background #E5E5E5
  .buttons
    display flex
    justify-content space-between
    align-items center
    .btn
      color #1677FF
      height 57px
      border-radius 0
      display flex
      align-items center
      justify-content center
      flex 1
      font-size fz14px
      &+.btn
        border-left 1px solid #E5E5E5

</style>