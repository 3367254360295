<template>
  <div>
    <!-- 课时记录有滚动翻页 -->
    <div
      class="list"
      v-show="history.length"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="20"
    >
      <HistoryCard v-for="item in history" :key="item.title" :info="item" />
      <p class="loading" v-if="loading">loading...</p>
    </div>
    <div class="empty" v-show="!history.length">
      <div class="icon"></div>
      <p class="tips">暂无课时记录，请等待班主任排课</p>
    </div>
  </div>
</template>
<script>
import infiniteScroll from "vue-infinite-scroll";
import HistoryCard from "./HistoryCard.vue";
import { getHistoryList } from "../../../api/mentor";

export default {
  directives: { infiniteScroll },
  props: ["history", "filter", "total"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async loadMore() {
      let { pageNo } = this.filter;
      if (pageNo * 10 < this.total) {
        this.loading = true;
        this.filter.pageNo += 1;
        let { list } = await getHistoryList({
          ...this.filter,
        });
        this.$emit("refreshData", list);
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
  },
  components: {
    HistoryCard,
  },
};
</script>
<style lang="stylus" scoped>
@import '../../../common.styl';
@import '../../../theme.styl';

.list {
  padding: 10px;

  .loading {
    text-align: center;
    line-height: 30px;
  }
}

.empty {
  padding-top: 173px;

  .icon {
    width: 132px;
    height: 116px;
    margin: 0 auto;
    bgFill();
    background-image: url('../../../assets/images/Frame.png');
  }

  .tips {
    padding-top: 32px;
    text-align: center;
    font-size: fz16px;
    color: #C6C6C6;
    line-height: 1;
  }
}
</style>